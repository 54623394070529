import styled, { keyframes } from 'styled-components';
import theme from 'components/styles/theme';

export const waving = keyframes`
  0% {
    background-position-x: 0;
  }

  100% {
    background-position-x: 192rem;
  }
`;

export const waving2 = keyframes`
  0% {
    background-position-x: 0;
  }

  100% {
    background-position-x: -192rem;
  }
`;

export const Container = styled.div`
  width: 100%;
  min-width: 192rem;
  min-height: 60rem;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: 800px) {
    min-width: 0;
  }
`;

export const TopSelectArea = styled.div`
  width: 100%;
  height: 5rem;
  min-width: 192rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media screen and (max-width: 800px) {
    min-width: 0;
    margin-bottom: 5rem;
  }
`;

export const TopItemWrapper = styled.div`
  width: 120rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  @media screen and (max-width: 800px) {
    width: 100%;
    justify-content: space-between;
  }
`;

export const TopSelectItem = styled.div<{ focused: boolean }>`
  width: 9rem;
  height: 100%;
  font-size: 1.4rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) =>
    props.focused ? theme.color.primary : theme.color.black};
  border-bottom: ${(props) =>
    props.focused
      ? `0.3rem solid ${theme.color.primary}`
      : '0.3rem solid transparent'};
  cursor: pointer;

  @media screen and (max-width: 800px) {
    width: 8rem;
  }
`;

export const TopRightArea = styled.div`
  display: flex;
  gap: 2rem;
  position: absolute;
  right: 0;
  height: 100%;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 800px) {
    bottom: -5rem;
    right: 1rem;
  }
`;

export const KeywordSearchArea = styled.div`
  width: 20rem;
  height: 3rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 800px) {
    width: 15rem;
  }
`;

export const KeywordSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 1.2rem;
  font-weight: 500;
  color: ${theme.color.black};
  background-color: ${theme.color.veryLightGrey};
  placeholder-color: ${theme.color.grey};
  border-radius: 5rem;
  padding: 1rem 1.5rem;
  border: 0;
`;

export const KeywordSearchIcon = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 0.7rem;
  cursor: pointer;
`;

export const TopOrderBySelect = styled.select`
  width: 10rem;
  height: 100%;
  border: 0;
  font-size: 1.4rem;
  padding: 1rem;

  @media screen and (max-width: 800px) {
    width: 8rem;
    font-size: 1.2rem;
  }
`;

export const ContentArea = styled.div`
  width: 100%;
  min-height: 60rem;
`;

export const BannerAreaWrapper = styled.div`
  width: 100%;
  background-color: ${theme.color.third};
  padding: 5rem 0;
  margin: 0 0 5rem;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 800px) {
    padding: 0;
  }
`;

export const BannerWave1 = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 10rem;
  background: url('/img/wave3.png');
  background-size: 100% 10rem;
  z-index: -1;
  animation: ${waving} 20s linear infinite;
  opacity: 0.4;
`;

export const BannerWave2 = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 10rem;
  background: url('/img/wave.png');
  background-size: 100% 10rem;
  z-index: -1;
  animation: ${waving2} 15s linear infinite;
  opacity: 0.6;
`;

export const BannerWave3 = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 10rem;
  background: url('/img/wave2.png');
  background-size: 100% 10rem;
  z-index: -1;
  animation: ${waving} 10s linear infinite;
  opacity: 0.7;
`;

export const BannerWave4 = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 10rem;
  background: url('/img/wave.png');
  background-size: 100% 10rem;
  z-index: -1;
  animation: ${waving2} 12s linear infinite;
  opacity: 1;
`;

export const BannerArea = styled.div`
  position: relative;
  width: 120rem;
  height: 50rem;
  display: flex;
  gap: 1rem;
  margin: auto;

  @media screen and (max-width: 800px) {
    width: 100%;
    flex-wrap: wrap;
    height: auto;
  }
`;

export const Banner1 = styled.div`
  width: 75rem;
  height: 50rem;
  cursor: pointer;

  @media screen and (max-width: 800px) {
    width: 100%;
    height: 30rem;
  }
`;

export const BannerRightWrapper = styled.div`
  width: 44rem;
  height: 50rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 800px) {
    width: 100%;
    height: auto;
  }
`;

export const Banner2 = styled.div`
  width: 44rem;
  height: 24.5rem;
  cursor: pointer;

  @media screen and (max-width: 800px) {
    width: 100%;
    height: 15rem;
  }
`;

export const BannerImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1.5rem;

  @media screen and (max-width: 800px) {
    object-fit: contain;
  }
`;

export const MiddleImgWrapper = styled.div`
  position: relative;
  width: 120rem;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding-bottom: 2rem;
  border-bottom: 2px solid ${theme.color.secondary};
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

export const MiddleString = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: large;
  color: #000;
`;

export const MiddleImg = styled.img`
  width: 100%;
  height: 50rem;
  object-fit: cover;
  border-radius: 1.5rem;

  @media screen and (max-width: 800px) {
    object-fit: contain;
  }
`;

export const SpecialPriceWrapper = styled.div`
  width: 120rem;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin: 0 auto;
  padding: 3rem 0;
  border-bottom: 2px solid ${theme.color.secondary};
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

export const SpecialPriceArea = styled.div`
  width: 120rem;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin: 0 auto;
  padding: 3rem 0;

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

export const SpecialTitle = styled.div`
  height: 3rem;
  font-size: 24px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: end;
  @media screen and (max-width: 800px) {
    padding: 0 1rem;
  }
`;

export const SpecialText = styled.div`
  height: 3rem;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: end;
`;

export const ProductArea = styled.div`
  width: 120rem;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin: 0 auto;
  padding: 1rem 0;

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

export const NoProductIndicator = styled.div`
  width: 100%;
  height: 40rem;
  font-size: 2rem;
  color: ${theme.color.lightGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15rem auto 25rem;
  font-family: 'GMarketSans';
`;

export const DayItem = styled.div`
  width: 28.5rem;
  height: 44rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  cursor: pointer;
  transition: 0.25s ease-in-out;

  &:hover {
    box-shadow: 0 0 0.2rem 0.2rem ${theme.color.primary};
  }

  @media screen and (max-width: 800px) {
    width: 95%;
    height: auto;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    border: 1px solid ${theme.color.primary};
    padding: 1rem;
  }
`;

export const ProductItem = styled.div`
  width: 28.5rem;
  height: 44rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  cursor: pointer;
  transition: 0.25s ease-in-out;

  &:hover {
    box-shadow: 0 0 0.2rem 0.2rem ${theme.color.primary};
  }

  @media screen and (max-width: 800px) {
    width: 95%;
    height: auto;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    border: 1px solid ${theme.color.primary};
    padding: 1rem;
  }
`;

export const ProductImg = styled.img`
  width: 28.5rem;
  height: 28rem;
  object-fit: cover;

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

export const ProductNm = styled.div`
  width: 28.5rem;
  height: 5rem;
  padding: 0.5rem;
  font-size: 1.6rem;
  font-weight: bold;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  line-height: 2.5rem;
  color: ${theme.color.black};
`;

export const ProductPrice = styled.div`
  font-size: 1.6rem;
  font-weight: 500;
  height: 1.6rem;
  padding: 1rem;
  text-decoration: line-through;
  color: ${theme.color.lightGrey};
  text-align: left;

  @media screen and (max-width: 800px) {
    text-align: right;
    padding: 0;
    width: 100%;
  }
`;

export const ProductDiscountPriceWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  padding: 1rem;

  @media screen and (max-width: 800px) {
    align-items: flex-end;
    justify-content: flex-end;
  }
`;

export const ProductDiscountRate = styled.div`
  color: #ff0000;
  font-size: 2rem;
  font-weight: bold;
`;

export const ProductPriceDiscount = styled.div`
  font-size: 2rem;
  color: ${theme.color.black};
`;

export const ProductPriceDiscountSpan = styled.span`
  font-weight: bold;
`;

export const PaginationWrapper = styled.div`
  width: 120rem;
  padding: 1rem 0;
  margin: 7rem auto;

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

export const PopupArea = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 999;
  gap: 1rem;
  top: calc(50% - 190px);
  justify-content: center;
  align-items: center;
`;

export const OnIgnoreForToday = styled.div`
  cursor: pointer;
`;

export const ButtonArea = styled.div`
  display: flex;
  gap: 2rem;
  font-size: medium;
  font-weight: 400;
  color: #fff;
  justify-content: end;
  padding: 1rem;
`;

export const OnClose = styled.div`
  cursor: pointer;
`;

export const PopupImg = styled.img`
  border-radius: 1.5rem;
`;
