export const isDev = false;
export const isOpt = true;

export const API_SERVER = isOpt
  ? 'https://doublt.shopping-ground.com/api/v1'
  : isDev
  ? ''
  : 'http://192.168.1.6:8080/api/v1';

export const COM_ID = 4;

export const pgList = [
  {
    code: 'html5_inicis.INIpayTest',
    name: '카드결제',
    img: '/icon/card.png',
    color: '',
    payMethod: 'card',
    paymentCd: 'C',
  },
  {
    code: 'html5_inicis.INIpayTest',
    name: '가상계좌',
    img: '/icon/wallet.png',
    color: '',
    payMethod: 'vbank',
    paymentCd: 'V',
  },
  {
    code: 'kakaopay.TC0ONETIME',
    name: '카카오페이',
    img: '/icon/kakao.webp',
    color: '',
    payMethod: 'card',
    paymentCd: 'K',
  },
  // {
  //   code: 'naverpay.SGNAVER',
  //   name: '네이버페이',
  //   img: '/icon/naver.png',
  //   color: '',
  //   payMethod: 'card',
  //   paymentCd: 'N',
  // },
  {
    code: 'tosspay.tosstest',
    name: '토스간편결제',
    img: '/icon/toss.png',
    color: '',
    payMethod: 'card',
    paymentCd: 'T',
  },
];
