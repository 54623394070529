import React, { Dispatch, SetStateAction } from 'react';
import * as s from './ProductListStyled';
import { Link } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import 'components/styles/Paging.css';
import { priceToString } from 'util/util';
import { FaSearch } from 'react-icons/fa';
import theme from 'components/styles/theme';
import { isMobile } from 'react-device-detect';
import CountdownClock from 'components/countdownClock';
import { useState, useEffect } from 'react';
interface ProductListProps {
  keyword: string;
  setKeyword: Dispatch<SetStateAction<string>>;
  userInfo: any;
  page: number;
  handlePageChange: (page: number) => void;
  pageOffset: number;

  orderBy: string;
  setOrderBy: Dispatch<SetStateAction<string>>;

  banner1Preview: string;
  banner2Preview: string;
  banner3Preview: string;
  banner1Keyword: string;
  banner2Keyword: string;
  banner3Keyword: string;

  totalCount: number;
  productList: any[];
  searchData: () => void;

  searchKeyword: string;
  setSearchKeyword: Dispatch<SetStateAction<string>>;
  searchByKeyword: () => void;
  checkSearchEnter: (event: any) => void;
  toDetail: (productId: any) => void;

  productTodayList: any;
  productSpecialList: any;
  countdownSettings: any;
}

const ProductListPresenter = (props: ProductListProps) => {
  //팝업

  // eslint-disable-next-line react/prop-types
  const Popup = ({ event, img, onClose, onIgnoreForToday }) => (
    <div style={{ width: '600px', height: '380px', zIndex: 999 }}>
      <s.PopupImg onClick={event} src={img} />
      <s.ButtonArea>
        <s.OnIgnoreForToday onClick={onIgnoreForToday}>
          오늘 하루 보지 않기
        </s.OnIgnoreForToday>
        <s.OnClose onClick={onClose}>Close</s.OnClose>
      </s.ButtonArea>
    </div>
  );

  const [popup1Visible, setPopup1Visible] = useState(true);
  const [popup2Visible, setPopup2Visible] = useState(true);

  const [ignorePopup1ForToday, setIgnorePopup1ForToday] = useState(false);
  const [ignorePopup2ForToday, setIgnorePopup2ForToday] = useState(false);

  useEffect(() => {
    if (ignorePopup1ForToday) {
      // 로컬 스토리지에 해당 정보를 저장
      localStorage.setItem('ignorePopup1ForToday', 'true');
    }
  }, [ignorePopup1ForToday]);

  useEffect(() => {
    if (ignorePopup2ForToday) {
      // 로컬 스토리지에 해당 정보를 저장
      localStorage.setItem('ignorePopup2ForToday', 'true');
    }
  }, [ignorePopup2ForToday]);

  useEffect(() => {
    // 컴포넌트가 마운트될 때 로컬 스토리지에서 정보를 가져와 상태 업데이트
    const storedIgnorePopup1ForToday = localStorage.getItem(
      'ignorePopup1ForToday',
    );
    const storedIgnorePopup2ForToday = localStorage.getItem(
      'ignorePopup2ForToday',
    );

    if (storedIgnorePopup1ForToday === 'true') {
      setIgnorePopup1ForToday(true);
    }

    if (storedIgnorePopup2ForToday === 'true') {
      setIgnorePopup2ForToday(true);
    }
  }, []);

  return (
    <s.Container>
      <s.PopupArea>
        {((!ignorePopup1ForToday && popup1Visible) ||
          (!ignorePopup2ForToday && popup2Visible)) && (
          <div
            style={{
              position: 'absolute',
              width: '100vw',
              height: '100vh',
              background: '#0000007a',
            }}
          ></div>
        )}
        {!ignorePopup1ForToday && popup1Visible && (
          <Popup
            event={() => {
              props.setKeyword('골프용품');
              setPopup1Visible(false);
              setPopup2Visible(false);
            }}
            img="/img/popup1.jpg"
            onClose={() => setPopup1Visible(false)}
            onIgnoreForToday={() => {
              setIgnorePopup1ForToday(true);
              setPopup1Visible(false);
            }}
          />
        )}
        {!ignorePopup2ForToday && popup2Visible && (
          <Popup
            event={() => {
              props.setKeyword('동남아');
              setPopup1Visible(false);
              setPopup2Visible(false);
            }}
            img="/img/popup2.jpg"
            onClose={() => setPopup2Visible(false)}
            onIgnoreForToday={() => {
              setIgnorePopup2ForToday(true);
              setPopup2Visible(false);
            }}
          />
        )}
      </s.PopupArea>
      <s.TopSelectArea>
        <s.TopItemWrapper>
          <s.TopSelectItem
            focused={props.keyword === ''}
            onClick={() => props.setKeyword('')}
          >
            전체보기
          </s.TopSelectItem>
          <s.TopSelectItem
            focused={props.keyword === 'HOT DEAL'}
            onClick={() => props.setKeyword('HOT DEAL')}
          >
            HOT DEAL
          </s.TopSelectItem>
          <s.TopSelectItem
            focused={props.keyword === '삼성가전'}
            onClick={() => props.setKeyword('삼성가전')}
          >
            삼성가전
          </s.TopSelectItem>
          <s.TopSelectItem
            focused={props.keyword === 'LG가전'}
            onClick={() => props.setKeyword('LG가전')}
          >
            LG가전
          </s.TopSelectItem>
          <s.TopSelectItem
            focused={props.keyword === '건강기능식품'}
            onClick={() => props.setKeyword('건강기능식품')}
          >
            건강기능식품
          </s.TopSelectItem>
          <s.TopSelectItem
            focused={props.keyword === '골프 전문관'}
            onClick={() => props.setKeyword('골프 전문관')}
          >
            골프 전문관
          </s.TopSelectItem>
          <s.TopSelectItem
            focused={props.keyword === '겨울패션'}
            onClick={() => props.setKeyword('겨울패션')}
          >
            겨울패션
          </s.TopSelectItem>
          <s.TopSelectItem
            focused={props.keyword === '여행레저'}
            onClick={() => props.setKeyword('여행레저')}
          >
            여행레저
          </s.TopSelectItem>
          <s.TopSelectItem
            focused={props.keyword === '생활편의'}
            onClick={() => props.setKeyword('생활편의')}
          >
            생활편의
          </s.TopSelectItem>
          <s.TopRightArea>
            <s.KeywordSearchArea>
              <s.KeywordSearchInput
                type="text"
                placeholder="검색어를 입력하세요."
                value={props.searchKeyword}
                onChange={(e) => props.setSearchKeyword(e.target.value)}
                onKeyDown={(e) => props.checkSearchEnter(e)}
              />
              <s.KeywordSearchIcon onClick={() => props.searchByKeyword()}>
                <FaSearch color={theme.color.black} size={'100%'} />
              </s.KeywordSearchIcon>
            </s.KeywordSearchArea>
            <s.TopOrderBySelect
              value={props.orderBy}
              onChange={(e) => props.setOrderBy(e.target.value)}
            >
              <option value="regDtm">최신순</option>
              <option value="popular">인기순</option>
            </s.TopOrderBySelect>
          </s.TopRightArea>
        </s.TopItemWrapper>
      </s.TopSelectArea>
      <s.ContentArea>
        {props.banner1Preview &&
        props.banner2Preview &&
        props.banner3Preview ? (
          <s.BannerAreaWrapper>
            {isMobile ? null : (
              <>
                <s.BannerWave1 />
                <s.BannerWave2 />
                <s.BannerWave3 />
                <s.BannerWave4 />
              </>
            )}

            <s.BannerArea>
              <s.Banner1 onClick={() => props.setKeyword(props.banner1Keyword)}>
                <s.BannerImg src={props.banner1Preview} />
              </s.Banner1>
              <s.BannerRightWrapper>
                <s.Banner2
                  onClick={() => props.setKeyword(props.banner2Keyword)}
                >
                  <s.BannerImg src={props.banner2Preview} />
                </s.Banner2>
                <s.Banner2
                  onClick={() => props.setKeyword(props.banner3Keyword)}
                >
                  <s.BannerImg src={props.banner3Preview} />
                </s.Banner2>
              </s.BannerRightWrapper>
            </s.BannerArea>
          </s.BannerAreaWrapper>
        ) : null}
        {props.keyword === '' && (
          <>
            <s.SpecialPriceWrapper>
              <s.SpecialTitle>하루특가</s.SpecialTitle>
              <s.SpecialText>오늘만 이가격! 내일이면 늦어요~</s.SpecialText>
              <CountdownClock />
              <s.SpecialPriceArea>
                {props.productTodayList.length > 0 ? (
                  props.productTodayList.map((product: any) => (
                    <s.ProductItem
                      onClick={() => props.toDetail(product.productId)}
                    >
                      <s.ProductImg src={product.thumbnail} />
                      <s.ProductNm>{product.productNm}</s.ProductNm>
                      <s.ProductPrice>
                        {priceToString(product.price)} 원
                      </s.ProductPrice>
                      <s.ProductDiscountPriceWrapper>
                        <s.ProductDiscountRate>
                          {Math.round(
                            ((product.price - product.priceDiscount) /
                              product.price) *
                              100,
                          )}
                          %
                        </s.ProductDiscountRate>
                        <s.ProductPriceDiscount>
                          <s.ProductPriceDiscountSpan>
                            {priceToString(product.priceDiscount)}
                          </s.ProductPriceDiscountSpan>
                          원
                        </s.ProductPriceDiscount>
                      </s.ProductDiscountPriceWrapper>
                    </s.ProductItem>
                  ))
                ) : (
                  <s.NoProductIndicator>
                    검색된 상품이 없습니다.
                  </s.NoProductIndicator>
                )}
              </s.SpecialPriceArea>
            </s.SpecialPriceWrapper>
            <s.SpecialPriceWrapper>
              <s.SpecialTitle>Hot Deal💖</s.SpecialTitle>
              <s.SpecialText>초특가 가격 모음전!</s.SpecialText>
              <s.SpecialPriceArea>
                {props.productSpecialList.length > 0 ? (
                  props.productSpecialList.map((product: any) => (
                    <s.ProductItem
                      onClick={() => props.toDetail(product.productId)}
                    >
                      <s.ProductImg src={product.thumbnail} />
                      <s.ProductNm>{product.productNm}</s.ProductNm>
                      <s.ProductPrice>
                        {priceToString(product.price)} 원
                      </s.ProductPrice>
                      <s.ProductDiscountPriceWrapper>
                        <s.ProductDiscountRate>
                          {Math.round(
                            ((product.price - product.priceDiscount) /
                              product.price) *
                              100,
                          )}
                          %
                        </s.ProductDiscountRate>
                        <s.ProductPriceDiscount>
                          <s.ProductPriceDiscountSpan>
                            {priceToString(product.priceDiscount)}
                          </s.ProductPriceDiscountSpan>
                          원
                        </s.ProductPriceDiscount>
                      </s.ProductDiscountPriceWrapper>
                    </s.ProductItem>
                  ))
                ) : (
                  <s.NoProductIndicator>
                    검색된 상품이 없습니다.
                  </s.NoProductIndicator>
                )}
              </s.SpecialPriceArea>
            </s.SpecialPriceWrapper>
          </>
        )}
        {props.keyword !== '' && (
          <s.MiddleImgWrapper>
            <s.MiddleImg src="/img/Artboard1.jpg"></s.MiddleImg>
          </s.MiddleImgWrapper>
        )}
        <s.SpecialPriceWrapper>
          <s.SpecialTitle>상품목록</s.SpecialTitle>
          <s.ProductArea>
            {props.productList.length > 0 ? (
              props.productList.map((product: any) => (
                <s.ProductItem
                  onClick={() => props.toDetail(product.productId)}
                >
                  <s.ProductImg src={product.thumbnail} />
                  <s.ProductNm>{product.productNm}</s.ProductNm>
                  <s.ProductPrice>
                    {priceToString(product.price)} 원
                  </s.ProductPrice>
                  <s.ProductDiscountPriceWrapper>
                    <s.ProductDiscountRate>
                      {Math.round(
                        ((product.price - product.priceDiscount) /
                          product.price) *
                          100,
                      )}
                      %
                    </s.ProductDiscountRate>
                    <s.ProductPriceDiscount>
                      <s.ProductPriceDiscountSpan>
                        {priceToString(product.priceDiscount)}
                      </s.ProductPriceDiscountSpan>
                      원
                    </s.ProductPriceDiscount>
                  </s.ProductDiscountPriceWrapper>
                </s.ProductItem>
              ))
            ) : (
              <s.NoProductIndicator>
                검색된 상품이 없습니다.
              </s.NoProductIndicator>
            )}
          </s.ProductArea>
        </s.SpecialPriceWrapper>
      </s.ContentArea>
      {props.productList.length < 1 ? null : (
        <s.PaginationWrapper>
          <Pagination
            activePage={props.page}
            itemsCountPerPage={props.pageOffset}
            totalItemsCount={props.totalCount}
            pageRangeDisplayed={5}
            firstPageText={''}
            lastPageText={''}
            prevPageText={'◀'}
            nextPageText={'▶'}
            onChange={props.handlePageChange}
          />
        </s.PaginationWrapper>
      )}
    </s.Container>
  );
};

export default ProductListPresenter;
