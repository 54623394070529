import React from 'react';
import * as s from './FooterStyled';
import { Link } from 'react-router-dom';

interface Props {
  comInfo: any;
}

const FooterPresenter = (props: Props) => {
  return (
    <s.Container>
      <s.LogoArea>
        <s.LogoImg src={props.comInfo?.logo} />
      </s.LogoArea>
      <s.TextArea>
        <s.BottomTextBright>
          주식회사 원스는 통신판매중개자로서 통신판매의 당사자가 아니며 개별
          판매자가 제공하는 서비스에 대한 이행, 계약사항 등과 관련한의무와
          책임은 거래당사자에게 있습니다.
        </s.BottomTextBright>
        <s.BottomTextBright>
          {`상호명: ${props.comInfo.comNm} · 대표이사:${props.comInfo.representitive} · 개인정보책임관리자:${props.comInfo.representitive}
          · 사업자등록번호: ${props.comInfo.regno}`}
        </s.BottomTextBright>
        <s.BottomTextBright>{`${props.comInfo.address}`}</s.BottomTextBright>
        <s.BottomTextBright>{`고객센터: 02-2025-4745~6 · 이메일:${props.comInfo.helpEmail}`}</s.BottomTextBright>
        <s.BottomTextDark>
          Copyright ©WONS Corp. All Rights Reserved.
        </s.BottomTextDark>
      </s.TextArea>
    </s.Container>
  );
};

export default FooterPresenter;
